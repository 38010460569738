<template>
  <div class="flex flex-col max-w-5xl mx-auto">
    <div id="toplinks" class="h-10 flex items-center justify-end px-4 border-b-2 border-b-black">
      <div v-if="status == 'authenticated'" class="loginsession text-right text-sm text-black">
        Welkom, {{ data?.id }}
        <button
          v-if="status == 'authenticated'"
          class="bg-white py-1 px-3 rounded ring-1 ring-slate-900/10 hover:ring-slate-900/20 ml-2"
          @click="signOut({ redirect: true, callbackUrl: '/login' })"
        >
          Uitloggen
        </button>
      </div>
    </div>
    <div id="wrapper" class="bg-white drop-shadow-lg dark:bg-black dark:text-white">
      <header class="w-full px-4">
        <NavigationMenu />
      </header>
      <div class="flex flex-row">
        <main class="flex-grow w-full h-full pt-8">
          <slot />
        </main>
      </div>
      <footer class="flex items-center justify-center w-full h-16 text-black border-t">
        <p class="text-gray-700">&copy; {{ new Date().getFullYear() }} OMWB</p>
      </footer>
    </div>
  </div>
</template>
<script setup lang="ts">
const { data, status, signOut } = useAuth();
</script>
